import React, { Component } from 'react'

export default class impressum extends Component {
  render() {
    return (
      <div className='impressum' style={{ backgroundColor: 'white' }}>
        <div className='container' >
          <div className="impressum_2" style={{ height: 600, zIndex: '1' }}>
            <div className="row " style={{ height: 600, marginTop: 100, paddingBottom: 50 }}>
              <div className='col-lg-12 col-12 ' style={{ textAlign: 'center' }}>
                <h1 style={{ marginBottom: 50, marginTop: 50, textAlign: 'center' }}>Impressum</h1>
                <p>SALIEVITO GmbH<br></br>
                  Spitzbergstra&szlig;e 5<br></br>
                  84149 Velden<br></br>
                  Deutschland</p>
                <p>Tel.: <a href="tel:+498920568556">089 20568556</a><br></br>
                  E-Mail: <a href="mailto:info@amari.bar">info@amari.bar</a></p>
                <p>Registergericht: Amtsgericht Landshut<br></br>
                Registernummer: HRB 12141</p>
                <p>Gesch&auml;ftsf&uuml;hrer: Zeno Kratzer</p>
                <p>Plattform der EU-Kommission zur Online-Streitbeilegung: <a href='https://ec.europa.eu/odr'>https://ec.europa.eu/odr</a><br></br>
               Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit. </p>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
