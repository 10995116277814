import React, { Component } from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px',
    marginLeft: "5px"

};

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="-100 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" /></svg></button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="100 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z" /></svg></button>
}

export default class ueberUns extends Component {
  render() {
    return (
      <div className='ueber' style={{ backgroundColor: 'white' }}>
        <div className='container' style={{  }}>
          <div className='row justify-content-center'>
            <div className='col-12'>
              <img className='img-fluid' style={{ height: 'auto', width: 'auto', marginTop: 50 }} alt="food" src="../assets/images/amari-bar_food_banner.jpg"></img>
            </div>

            <div className='col-12' style={{ marginTop: 100 }}>
              <div className='row justify-content-center align-items-start"'>
                <div className='col-lg-4 col-md-12 col-12'>
                  <h1 style={{ float: 'right', marginRight: 20 }}> ÜBER UNS</h1>
                </div>
                <div className='col-lg-4 col-md-12 col-12'>
                  <p style={{ float: 'left' }}>
                    Business Catering für Konferenzen, Tagungen, Messen oder Seminare. Du bist auf der Suche nach Business Lunch, Lunchpakete oder Frühstück, melde dich jederzeit. Wir liefern auch als Kantinenersatz.
                    Event Catering für Firmenfeiern, Weihnachtsfeiern oder Seminare. Du suchst etwas für euren Business Lunch oder Frühstück?
                    Privates Catering für eine Hochzeit, deinen Geburtstag oder ein Grillfest. Du planst eine Gartenparty oder suchst das perfekte Essen zu einer Location?
                  </p>
                </div>


              </div>
            </div>

            <div className='col-10 text-center' style={{ marginTop: 100 }}>
              <div className='row justify-content-between'>
                <div className='col-lg-5 col-md-12 col-12' style={{ marginBottom: 50 }}>
                  {/* <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/ueberuns1.png"></img> */}
                  <a style={{ textDecoration: 'none', color: 'black' }} href='/food'><h1 style={{ marginTop: 20 }}> FOOD </h1></a>
                  <p>
                    Wir kochen frisch, mit den besten Zutaten und glauben dabei an die Nachhaltigkeit. Die Qualität unserer Speisen sitzt, komm vorbei und mach dir selbst ein Bild davon. Keine Sorge, wir haben für alle Geschmäcker etwas vorbereitet.
                  </p>
                </div>
                <div className='col-lg-5 col-md-12 col-12' style={{ marginBottom: 50 }}>
                  {/* <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/ueberuns1.png"></img> */}
                  <a style={{ textDecoration: 'none', color: 'black' }} href='/drinks'><h1 style={{ marginTop: 20 }}> DRINKS </h1></a>
                  <p>
                    Wir bestechen als neue Bar in der Maxvorstadt durch eine qualitativ hochwertige Barkarte. Wir haben für euch Weine ausgewählt, eigene Aperitif-Kreationen entworfen und Obst und Gemüse eingelegt, damit wir euch mit vorzüglichen Cocktails verwöhnen können.

                  </p>
                </div>
              </div>
            </div>

            <div className='col-12' style={{ marginBottom: 100 }}>
              <div className='row align-items-center'>
                <div className='col-lg-6 col-12'>
                  <Slide {...properties}>
                    <div className="each-slide-effect">
                      <div>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_1" src="../assets/images/Location LH2/amari-bar_lh2_1.jpg"></img>
                      </div>
                    </div>
                    <div className="each-slide-effect">
                      <div>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_2" src="../assets/images/Location LH1/amari-bar_lh1_2.jpg"></img>
                      </div>
                    </div>
                    <div className="each-slide-effect">
                      <div>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_3" src="../assets/images/Location LH2/amari-bar_lh2_3.jpg"></img>
                      </div>
                    </div>
                    <div className="each-slide-effect">
                      <div>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_4" src="../assets/images/Location LH1/amari-bar_lh1_4.jpg"></img>
                      </div>
                    </div>
                    <div className="each-slide-effect">
                      <div>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_5" src="../assets/images/Location Bar/amari-bar_bar_5.jpg"></img>
                      </div>
                    </div>
                    <div className="each-slide-effect">
                      <div>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_5" src="../assets/images/Location Bar/amari-bar_bar_1.jpg"></img>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className='col-lg-6 col-12'>
                  <h1 style={{ textAlign: 'right', marginTop: 20 }}> EVENT LOCATION</h1>
                  <p style={{textAlign: 'right'}}>
                    Ihr sucht nach einer Location für euer Teamevent, einen Geburtstag oder einen Ort an dem gefeiert werden kann, wenn sich zwei Menschen, die sich mögen das Ja-Wort geben? Dann meldet euch gerne bei uns! Zusammen mit dem Kunstlabor2 bereichern wir dein Event mit unvergesslichen Erinnerungen. Wir haben die passenden Eventflächen und die dazugehörige Kulinarik.
                  </p>
                  <div>
                    <a href={"/locations"}>
                      <button className='button-white-arrow' style={{ float: 'right', marginLeft: 20, paddingLeft: 30, paddingRight: 30, marginTop: 10, height: 40, width: 76, zIndex: 2, backgroundSize: '25%' }}> </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12' style={{ marginBottom: 100 }}>
              <div className='row align-items-center'>
                <div className='col-lg-6 col-12'>
                  <h1 style={{ textAlign: 'left' }}> OFFENE JOBS </h1>
                  <div>
                    <a href={"/jobs"}>
                      <button className='button-white' style={{ float: 'left', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 10, height: 40, zIndex: 2, marginBottom: 20 }}> ZU DEN JOBS </button>
                    </a>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/Location Bar/amari-bar_bar_6.jpg"></img>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    )
  }
}
