function Event(props) {

    return(
        <div className='col-lg-5 col-md-12 col-12' style={{ height: '100px', borderStyle: 'solid', borderWidth: 3, borderColor: 'black', margin: 5 }}>
        <div className='row text-center'> <p style={{ marginTop: 5 }}> {props.dateAndTime} </p> </div>
        <div className='row text-center'> <h1> {props.name} </h1> </div>
    </div>
    );

}

export default Event;