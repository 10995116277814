import React, { useContext } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { HashLink } from 'react-router-hash-link';
import 'react-photo-view/dist/react-photo-view.css';
import Pdf from '../../shared/amari-bar_speisekarte.pdf'
import BrunchPdf from '../../shared/amari-bar_brunchkarte.pdf'

import { ReservationContext, BookContext } from '../../App';

const Food = () => {

    const reservationCtx = useContext(ReservationContext);
    const bookCtx = useContext(BookContext)


    return (
        <div className='food' style={{ backgroundColor: 'white' }}>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto', marginTop: 50 }} alt="food" src="../assets/images/amari-bar_food_banner.jpg"></img>
                    </div>

                    <div className='col-12' style={{ marginTop: 100 }}>
                        <div className='row justify-content-center align-items-start'>
                            <div className='col-lg-4 col-md-12 col-12'>
                                <h1 className='foodDrinkH1' style={{ float: 'right', marginRight: 20 }}> FOOD</h1>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12'>
                                <p className='foodDrinkP' style={{ float: 'left' }}>
                                    Wir kochen frisch, mit den besten Zutaten und glauben dabei an die Nachhaltigkeit. Die Qualität unserer Speisen sitzt, komm vorbei und mach dir selbst ein Bild davon. Keine Sorge, wir haben für alle Geschmäcker etwas vorbereitet.
                                </p>
                            </div>


                        </div>
                    </div>

                    <div className='row foodDrinkButtonRow justify-content-center' style={{ marginBottom: 110, marginTop: 80 }}>

                        <div className='col-lg-6 col-md-12 col-12 foodDrink-button-div'>
                            <div className='mediaiHelper'style={{float: 'right'}}>
                                <a href={BrunchPdf} without rel="noopener noreferrer" target="_blank">
                                    <button className='button-white-foodDrink' style={{ paddingLeft: 30, paddingRight: 30, marginTop: 20, height: 50 }}> ZUR BRUNCHKARTE </button>
                                </a><br/>
                                <a href={Pdf} without rel="noopener noreferrer" target="_blank">
                                    <button className='button-white-foodDrink' style={{ paddingLeft: 30, paddingRight: 30, marginTop: 20, height: 50 }}> ZUR SPEISEKARTE </button>
                                </a>
                                {/* <a href="/foodKarte">
                                <button className='button-white-foodDrink' style={{ paddingLeft: 30, paddingRight: 30, marginTop: 20, height: 50, zIndex: 2, float: 'right' }}> ZUR SPEISEKARTE </button>
                            </a> */}
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-12 foodDrink-button-div'>
                            <button className='button-white-foodDrink' style={{ paddingLeft: 30, paddingRight: 30, marginTop: 20, height: 50, zIndex: 2, }} onClick={reservationCtx.toggleReservation}> JETZT RESERVIEREN </button>
                        </div>

                    </div>
                    {/* <div className='row justify-content-between text-center' style={{ marginTop: 50, marginBottom: 100 }}>
                        <div className='col-lg-3 col-md-12 col-12'>
                            <img className='img-fluid' style={{ height: 'auto', width: '100px', marginBottom: 41.14 }} alt="food" src="../assets/icons/icon_obst.png"></img>
                            <h3> OBST </h3>
                            <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                        </div>
                        <div className='col-lg-3 col-md-12 col-12'>
                            <img className='img-fluid' style={{ height: '100px', width: 'auto', marginBottom: 20 }} alt="food" src="../assets/icons/icon_gemüse.png"></img>
                            <h3> GEMÜSE </h3>
                            <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                        </div>
                        <div className='col-lg-3 col-md-12 col-12'>
                            <img className='img-fluid' style={{ height: 'auto', width: '100px', marginBottom: 49.484 }} alt="food" src="../assets/icons/icon_fisch.png"></img>
                            <h3> FISCH </h3>
                            <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                        </div>
                        <div className='col-lg-3 col-md-12 col-12'>
                            <img className='img-fluid' style={{ height: '100px', width: 'auto', marginBottom: 20 }} alt="food" src="../assets/icons/icon_fleisch.png"></img>
                            <h3> FLEISCH </h3>
                            <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                        </div>

                    </div> */}

                    <div className='col-12 text-center' style={{ marginBottom: 150, marginTop: 100 }}>
                        <h1 style={{ marginBottom: 50 }}>GALERIE</h1>
                        <div className='thumbnail-gallery'>
                            <PhotoProvider>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_1.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_1_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_5.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_5_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_3.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_3_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_4.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_4_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_9.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_9_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_6.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_6_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_7.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_7_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_8.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_8_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Food Galerie/amari-bar_food_banner_2.jpg">
                                    <img src="../assets/images/Food Galerie/amari-bar_food_banner_2_thumb.jpg" alt="" />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                    </div>

                    {/*                     <div className='row justify-content-center'>
                        <div className='col-12 text-center' style={{ backgroundColor: 'black', height: 250, margin: 50, padding: 100, marginBottom: 100 }}>
                            <h1 style={{ color: 'white' }}> WIR MACHEN CATERING!</h1>
                        </div>
                    </div> */}

                    <div className='row justify-content-center cateringBannerRow'>
                        <div className='col-12 text-center' style={{ backgroundColor: 'black', height: 350, padding: 100, marginBottom: 100 }}>
                            <h1 style={{ color: 'white' }}> WIR MACHEN CATERING! </h1>
                            <button className='button-black' style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 7, marginTop: 40, height: 50, zIndex: 2, marginBottom: 50, borderColor: 'white' }} onClick={bookCtx.toggleBook}> JETZT ANFRAGEN </button>

                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: 100 }}>
                        <div className='col-lg-4 col-md-12 col-12' style={{ paddingLeft: 0, marginBottom: 20 }}>
                            <div className='row'>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/Location LH1/amari-bar_lh1_1.jpg"></img>
                            </div>
                            <div className='row'>
                                <h3 style={{ marginTop: 20 }}> LICHTHOF 1 </h3>
                                <p> 250 qm mit gemütlicher Holzterrasse für bis zu 250 Personen im Herzen Münchens. Das ist der perfekte Spot für euer Firmenevent, deine Geburtstagsfeier oder Hochzeit. </p>
                                <div>
                                    <HashLink smooth to="/locations#lichthof1">
                                        <button className='button-white-arrow' style={{ float: 'left', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 5, height: 40, width: 76, zIndex: 2, backgroundSize: '25%' }}>  </button>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12' style={{ paddingLeft: 0, marginBottom: 20 }}>
                            <div className='row'>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/Location LH2/amari-bar_lh2_1.jpg"></img>
                            </div>
                            <div className='row'>
                                <h3 style={{ marginTop: 20 }}> LICHTHOF 2 </h3>
                                <p> 250 qm im urbanen Look und Graffiti Style für bis zu 250 Personen. Individuell nutzbar für Firmenevents, Geburtstage oder Streetart Workshops. </p>
                                <div>
                                    <HashLink smooth to="/locations#lichthof2">
                                        <button className='button-white-arrow' style={{ float: 'left', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 5, height: 40, width: 76, zIndex: 2, backgroundSize: '25%' }}>  </button>
                                    </HashLink>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-12 col-12' style={{ paddingRight: 12, paddingLeft: 0, marginBottom: 20 }}>
                            <div className='row'>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/Location Bar/amari-bar_bar_7.jpg"></img>
                            </div>
                            <div className='row'>
                                <h3 style={{ marginTop: 20 }}> BAR ALS LOCATION </h3>
                                <p> Als Bar laden wir dich ein, genussreiche Stunden auf unserer großzügigen Sonnenterrasse oder im lichtdurchfluteten Inneren im Industrial Style zu verbringen. Möchtest du deinen Arbeitstag mit einem Espresso Martini beenden, oder dich von unseren Signatures verzaubern lassen? </p>
                                <div>
                                    <HashLink smooth to="/locations#barlocation">
                                        <button className='button-white-arrow' style={{ float: 'left', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 5, height: 40, width: 76, zIndex: 2, backgroundSize: '25%' }}>  </button>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                        {/*                         <div className='col-lg-4 col-md-12 col-12' style={{ backgroundColor: 'black', marginBottom: 20 }} >
                            <div className='row align-items-center text-center' style={{ marginTop: '12vh', marginBottom: '10vh' }} >
                                <div className='col-12' >
                                    <h1 style={{ color: 'white' }}> DU </h1>
                                    <h1 style={{ color: 'white' }}> BENÖTIGST </h1>
                                    <h1 style={{ color: 'white' }}> MEHR </h1>
                                    <h1 style={{ color: 'white' }}> PLATZ?</h1>
                                </div>
                            </div>
                        </div> */}

                    </div>


                </div>
            </div >
        </div>
    )

}; export default Food;
