import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line
import { faFacebookSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
//import { hover } from '@testing-library/user-event/dist/hover';

class Footer extends Component {

    render() {

        return (
            <div className="footer" style={{ backgroundColor: 'black', paddingTop: 20 }}>

                <div className="row footer-down justify-content-center" style={{ margin: 0 }}>
                    <div className="col-12 d-inline-flex justify-content-center">
                        {/* <a href="/">
                            <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>KONTAKT</h2>
                        </a>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>|</h2> */}
                        <a href="/impressum">
                            <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>IMPRESSUM</h2>
                        </a>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>|</h2>
                        <a href="/datenschutz">
                            <h2 style={{ color: "white", marginBottom: 40, marginTop: 10}}>DATENSCHUTZ</h2>
                        </a>
                        {/* <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>|</h2>
                        <a href="/agb">
                            <h2 style={{ color: "white", marginBottom: 40, marginTop: 10 }}>AGB</h2>
                        </a> */}
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <h2 style={{ color: "white", fontSize: "20px", marginBottom: -5, }}>FOLLOW US ON</h2>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <a className="btn" href="https://www.facebook.com/profile.php?id=100078561723884" target="_blank" rel="noreferrer" style={{ fontSize: "40px" }}><FontAwesomeIcon icon={faFacebookSquare} color="white" /></a>
                        <a className="btn" href="https://www.instagram.com/amari.bar/" target="_blank" rel="noreferrer" style={{ fontSize: "40px" }}><FontAwesomeIcon icon={faInstagramSquare} color="white" /></a>
                        {/* <a className="btn" href="https://www.youtube.com/" target="_blank" rel="noreferrer" style={{ fontSize: "40px" }}><FontAwesomeIcon icon={faYoutubeSquare} color="white" /></a> */}
                    </div>
                    <div className="col-12 d-inline-flex justify-content-center" style={{ marginTop: 20 }}>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>089 20568556</h2>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>|</h2>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}> DACHAUER STR. 90 </h2>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10, marginRight: 15 }}>|</h2>
                        <h2 style={{ color: "white", marginBottom: 40, marginTop: 10}}> 80335 MÜNCHEN </h2>
                    </div>
                    <div className="col-12 d-inline-flex justify-content-center">
                        <h2 style={{ color: "white", marginTop: 10, marginRight: 15 }}>© 2023 AMARI BAR</h2>
                        </div>
                        <div className="col-12 d-inline-flex justify-content-center">
                        <h2 style={{ color: "white", marginBottom: 40, marginRight: 15 }}><a href='https://www.adfec.media' style={{ color: "white"}}>WEBDESIGN & UMSETZUNG VON ADFEC.MEDIA</a></h2>
                        </div>
                </div>
            </div>
        );
    }
}

export default Footer;