import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

export default class ueberUns extends Component {
    render() {
        return (
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <img className='img-fluid' style={{ height: 'auto', width: 'auto', marginTop: 50, marginBottom: 50 }} alt="food" src="../assets/images/lichthof1_banner.png"></img>
                    </div>
                    <div className='col-9 text-center'>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-lg-8 col-md-12 col-12 text-center' style={{ marginBottom: 20 }}>
                                <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-9 text-center'>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h2 style={{ marginTop: 30, color: 'white' }}> ZWEI LICHTHÖFE </h2>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h2 style={{ marginTop: 30, color: 'white' }}> CATERING </h2>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h2 style={{ marginTop: 30, color: 'white' }}> BIS ZU 1300 GÄSTE </h2>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h2 style={{ marginTop: 30, color: 'white' }}> SONNEN TERASSE </h2>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 text-center' style={{ marginBottom: 150, marginTop: 100 }}>
                        <h1 style={{ marginBottom: 50 }}>GALERIE</h1>
                        <div className='thumbnail-gallery'>
                        <PhotoProvider>
                            <PhotoView src="../assets/images/filler1.png">
                                <img src="../assets/images/filler1.png" alt=""  />
                            </PhotoView>
                            <PhotoView src="../assets/images/filler2.png">
                                <img src="../assets/images/filler2.png" alt="" />
                            </PhotoView>
                            <PhotoView src="../assets/images/filler3.png">
                                <img src="../assets/images/filler3.png" alt="" />
                            </PhotoView>
                            <PhotoView src="../assets/images/filler1.png">
                                <img src="../assets/images/filler1.png" alt="" />
                            </PhotoView>
                            <PhotoView src="../assets/images/filler1.png">
                                <img src="../assets/images/filler1.png" alt="" />
                            </PhotoView>
                            <PhotoView src="../assets/images/filler2.png">
                                <img src="../assets/images/filler2.png" alt="" />
                            </PhotoView>
                            <PhotoView src="../assets/images/filler3.png">
                                <img src="../assets/images/filler3.png" alt="" />
                            </PhotoView>
                            
                        </PhotoProvider>
                        </div>
                    </div>



                    <div className='col-12 text-center' style={{ marginBottom: 50, marginTop: 100 }}>
                        <h1 style={{ marginBottom: 50 }}> WEITERE LOCATIONS </h1>
                    </div>




                    <div className='row' style={{ marginBottom: 100 }}>
                        <div className='col-lg-4 col-md-12 col-12' style={{paddingLeft: 0, marginBottom: 20}}>
                            <div className='row'>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/ueberuns2.png"></img>
                            </div>
                            <div className='row'>
                                <h3 style={{ marginTop: 20 }}> LICHTHOF 1 </h3>
                                <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
                                <div>
                                    <Link to={"/"}>
                                        <button style={{ float: 'left', backgroundColor: 'transparent', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 5, height: 40, zIndex: 2 }}> ---{'>'} </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12' style={{ paddingRight: 12, paddingLeft: 0, marginBottom: 20}}>
                            <div className='row'>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/ueberuns2.png"></img>
                            </div>
                            <div className='row'>
                                <h3 style={{ marginTop: 20 }}> BAR ALS LOCATION </h3>
                                <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
                                <div>
                                    <Link to={"/"}>
                                        <button style={{ float: 'left', backgroundColor: 'transparent', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 5, height: 40, zIndex: 2 }}> ---{'>'} </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12' style={{ backgroundColor: 'black', marginBottom: 20}} >
                            <div className='row align-items-center text-center' style={{ marginTop: '12vh', marginBottom: '10vh'}} >
                                <div className='col-12' >
                                    <h1 style={{ color: 'white' }}> DU </h1>
                                    <h1 style={{ color: 'white' }}> BENÖTIGST </h1>
                                    <h1 style={{ color: 'white' }}> MEHR </h1>
                                    <h1 style={{ color: 'white' }}> PLATZ?</h1>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className='col-12' style={{ marginBottom: 100 }}>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <h1 style={{ textAlign: 'left' }}> DU HAST SCHON </h1>
                                <h1 style={{ textAlign: 'left' }}> EINE LOCATION? </h1>
                                <p> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                                <div>
                                    <Link to={"/"}>
                                        <button style={{ float: 'left', backgroundColor: 'transparent', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 10, height: 40, zIndex: 2, marginBottom: 20 }}> ---{'>'} </button>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/ueberuns2.png"></img>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}
