import React, { useContext } from 'react';
import { MenuContext, ReservationContext } from '../App';


const Header = () => {

    const menuCtx = useContext(MenuContext)
    const reservationCtx = useContext(ReservationContext)
    // const bookCtx = useContext(BookContext)


    return (


        <div className='' >
            <div className="header" style={{ backgroundColor: 'white' }} >
                <div className='container' style={{ paddingTop: 0, backgroundColor: 'white' }}>
                    <div className="row align-items-center" style={{ zIndex: '2', backgroundColor: 'white' }}>


                        <div className='col-6 logoHeader' style={{ justifyContent: 'start' }}>
                            <a href="/"> <img className='header-logo' style={{ height: 90, marginTop: 20, marginBottom: 15}} alt="logo" src="../assets/images/amari-logo-s.png"></img></a>
                        </div>
                        <div className='col-6 burgerMenuHeader' style={{ justifyContent: 'end'}}>
                            <button className='burgerMenuHeaderButton' style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 10}} onClick={menuCtx.toggleMenu}>
                                <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="25" height="3" fill="black" />
                                    <rect y="5" width="25" height="3" fill="black" />
                                    <rect y="10" width="25" height="3" fill="black" />
                                </svg>
                            </button>

                        </div>




                        <div className='col-md-6 col-12 d-inline-flex menuPointsHeader' style={{ justifyContent: 'end' }}>
                            {/* <h3 style={{ color: "black", marginTop: 15 }}> 089 12345678ㅤ</h3> */}
                            {/* <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 10, pointerEvents: 'none' }}> <h4>089 12345678</h4></button> */}
                            {/* <h3 style={{ color: "black", marginTop: 15 }} onClick={this.toggleNavbar}>  |ㅤRESERVIERENㅤ </h3> */}
                            {/* <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 10, pointerEvents: 'none' }}> <h4>ㅤ|ㅤ</h4></button> */}
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 10 }}> <h1 style={{ fontSize: 16 }}>089 20568556</h1></button>
                            {/* <h3 style={{ color: "black", marginTop: 15,  }} onClick={() => this.toggleMenu()}> |ㅤMENU </h3> */}
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 10, pointerEvents: 'none' }}> <h4 style={{ fontSize: 16 }}>ㅤ|ㅤ</h4></button>
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 10 }} onClick={reservationCtx.toggleReservation}> <h1 style={{ fontSize: 16 }}>RESERVIEREN</h1></button>

                        </div>
                    </div>


                </div>
                <div className="row align-items-center menuHeaderRow" style={{ zIndex: '2', backgroundColor: 'white' }}>
                    <div className='col-12 d-inline-flex menuPointsHeader' style={{ justifyContent: 'center', paddingBottom: 30 }}>
                        <a href="/food">
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 16 }}>
                                <h1 style={{ fontSize: 16, marginRight: 50 }}>
                                    FOOD
                                </h1>
                            </button>
                        </a>
                        <a href="/drinks">
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 16 }}>
                                <h1 style={{ fontSize: 16, marginRight: 50 }}>
                                    DRINKS
                                </h1>
                            </button>
                        </a>
                        <a href="/locations">
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 16 }}>
                                <h1 style={{ fontSize: 16, marginRight: 50 }}>
                                    EVENTS
                                </h1>
                            </button>
                        </a>
                        <a href="/ueber">
                            <button style={{ backgroundColor: 'transparent', color: 'black', border: 'none', marginTop: 16 }}>
                                <h1 style={{ fontSize: 16 }}>
                                    ABOUT US
                                </h1>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default Header;