import React, { useContext } from 'react'
// import ReactPlayer from 'react-player'
// import Newsletter from '../detail_components/newsletter';
import { Link } from 'react-router-dom'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { BookContext } from '../../App';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px',
    marginLeft: "5px"

};

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="-100 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" /></svg></button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="100 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z" /></svg></button>
}






const Location = () => {


    const bookCtx = useContext(BookContext)

    return (
        <div className='locations' style={{ backgroundColor: 'white' }}>
            {/* <div className='player-wrapper' style={{ width: 'auto', height: 'auto' }}>
                <ReactPlayer
                    className='react-player'
                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                    playing
                    muted
                    width="100%"
                    height="100%"
                    controls={true}
                    style={{ position: 'absolute', zIndex: '1' }}
                />
            </div> */}
            <div className='container' style={{ }}>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center' style={{ marginBottom: 20, paddingTop: 100 }}>
                        <h1>EVENT LOCATION</h1>
                        <h1>MITTEN IN MÜNCHEN</h1>
                    </div>
                    <div className='col-9 text-center'>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-lg-8 col-md-12 col-12 text-center' style={{ marginBottom: 20 }}>
                                <p> Ihr sucht nach einer Location für euer Teamevent, einen Geburtstag oder einen Ort an dem gefeiert werden kann, wenn sich zwei Menschen, die sich mögen das Ja-Wort geben? Dann meldet euch gerne bei uns! Zusammen mit dem Kunstlabor2 bereichern wir dein Event mit unvergesslichen Erinnerungen. Wir haben die passenden Eventflächen und die dazugehörige Kulinarik. </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-9 text-center'>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h3 style={{ marginTop: 30, color: 'white' }}> ZWEI LICHTHÖFE </h3>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h3 style={{ marginTop: 30, color: 'white' }}> CATERING </h3>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h3 style={{ marginTop: 30, color: 'white' }}> BIS ZU 500 GÄSTE </h3>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12' style={{ height: '90px', backgroundColor: 'black', margin: 5 }}>
                                <h3 style={{ marginTop: 30, color: 'white' }}> SONNEN TERASSE </h3>
                            </div>
                        </div>
                    </div> */}

                    <div className='col-12' id='lichthof1' style={{ marginBottom: 100, marginTop: 100 }}>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <Slide {...properties}>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_1" src="../assets/images/Location LH1/amari-bar_lh1_1.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_2" src="../assets/images/Location LH1/amari-bar_lh1_2.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_3" src="../assets/images/Location LH1/amari-bar_lh1_3.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_4" src="../assets/images/Location LH1/amari-bar_lh1_4.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_5" src="../assets/images/Location LH1/amari-bar_lh1_5.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_6" src="../assets/images/Location LH1/amari-bar_lh1_6.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_7" src="../assets/images/Location LH1/amari-bar_lh1_7.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh1_8" src="../assets/images/Location LH1/amari-bar_lh1_8.jpg"></img>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <h1 style={{ textAlign: 'right', marginTop: 20 }}> LICHTHOF 1 </h1>
                                <p style={{ textAlign: 'right' }}> 250 qm mit gemütlicher Holzterrasse für bis zu 250 Personen im Herzen Münchens. Das ist der perfekte Spot für euer Firmenevent, deine Geburtstagsfeier oder Hochzeit.</p>
                                <div className='row justify-content-end'>
                                    <button style={{ float: 'right', backgroundColor: 'transparent', marginLeft: 20, paddingLeft: 10, marginRight: 12, paddingRight: 10, paddingTop: 5, marginTop: 10, height: 40, zIndex: 2, pointerEvents: 'none', width: 100, borderColor: 'black', color: 'black' }}> 250 M2 </button>
                                    <button style={{ float: 'right', backgroundColor: 'transparent', marginLeft: 8, marginRight: 12, paddingLeft: 10, paddingRight: 10, paddingTop: 5, marginTop: 10, height: 40, zIndex: 2, pointerEvents: 'none', width: 250, borderColor: 'black', color: 'black' }}> BIS ZU 250 PERSONEN </button>

                                    <Link to={""}>
                                        <button className='button-black' style={{ float: 'right', paddingLeft: 30, paddingRight: 30, paddingTop: 5, marginTop: 10, height: 40, zIndex: 1, marginBottom: 20, borderColor: 'black' }} onClick={bookCtx.toggleBook}> BOOK NOW! </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12' id='lichthof2' style={{ marginBottom: 100 }}>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <h1 style={{ textAlign: 'left' }}> LICHTHOF 2 </h1>
                                <p> 250 qm im urbanen Look und Graffiti Style für bis zu 250 Personen. Individuell nutzbar für Firmenevents, Geburtstage oder Streetart Workshops.</p>
                                <div className='row justify-content-start'>
                                    <button style={{ float: 'left', backgroundColor: 'transparent', marginLeft: 12, paddingLeft: 10, marginRight: 12, paddingRight: 10, paddingTop: 5, marginTop: 10, height: 40, zIndex: 2, pointerEvents: 'none', width: 100, borderColor: 'black', color: 'black' }}> 250 M2 </button>
                                    <button style={{ float: 'left', backgroundColor: 'transparent', marginLeft: 8, marginRight: 12, paddingLeft: 10, paddingRight: 10, paddingTop: 5, marginTop: 10, height: 40, zIndex: 2, pointerEvents: 'none', width: 250, borderColor: 'black', color: 'black' }}> BIS ZU 250 PERSONEN </button>

                                    <Link to={""}>
                                        <button className='button-black' style={{ float: 'left', paddingLeft: 30, paddingRight: 30, paddingTop: 5, marginTop: 10, height: 40, zIndex: 1, marginBottom: 20, borderColor: 'black' }} onClick={bookCtx.toggleBook}> BOOK NOW! </button>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <Slide {...properties}>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_1" src="../assets/images/Location LH2/amari-bar_lh2_1.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_2" src="../assets/images/Location LH2/amari-bar_lh2_2.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_3" src="../assets/images/Location LH2/amari-bar_lh2_3.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_4" src="../assets/images/Location LH2/amari-bar_lh2_4.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="lh2_5" src="../assets/images/Location LH2/amari-bar_lh2_5.jpg"></img>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>


                    <div className='col-12' id='barlocation' style={{ marginBottom: 100 }}>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <Slide {...properties}>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_1" src="../assets/images/Location Bar/amari-bar_bar_1.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_2" src="../assets/images/Location Bar/amari-bar_bar_2.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_3" src="../assets/images/Location Bar/amari-bar_bar_3.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_4" src="../assets/images/Location Bar/amari-bar_bar_4.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_5" src="../assets/images/Location Bar/amari-bar_bar_5.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_6" src="../assets/images/Location Bar/amari-bar_bar_6.jpg"></img>
                                        </div>
                                    </div>
                                    <div className="each-slide-effect">
                                        <div>
                                            <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="bar_7" src="../assets/images/Location Bar/amari-bar_bar_7.jpg"></img>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <h1 style={{ textAlign: 'right', marginTop: 20 }}> BAR ALS LOCATION</h1>
                                <p style={{ textAlign: 'right' }}> Wir bestechen als neue Bar in der Maxvorstadt durch eine qualitativ hochwertige Barkarte. Wir haben für euch Weine ausgewählt, eigene Aperitif-Kreationen entworfen und Obst und Gemüse eingelegt, damit wir euch mit vorzüglichen Cocktails bestechen können.</p>
                                <div className='row justify-content-end'>
                                    {/*                                     <button style={{ float: 'right', backgroundColor: 'transparent', marginLeft: 20, paddingLeft: 10, marginRight: 12, paddingRight: 10, paddingTop: 5, marginTop: 10, height: 40, zIndex: 2, pointerEvents: 'none', width: 100, borderColor: 'black' }}> 1000 M2 </button>
                                    <button style={{ float: 'right', backgroundColor: 'transparent', marginLeft: 8, marginRight: 12, paddingLeft: 10, paddingRight: 10, paddingTop: 5, marginTop: 10, height: 40, zIndex: 2, pointerEvents: 'none', width: 250, borderColor: 'black' }}> BIS ZU 2000 PERSONEN </button>
 */}
                                    <Link to={""}>
                                        <button className='button-black' style={{ float: 'right', paddingLeft: 30, paddingRight: 30, paddingTop: 5, marginTop: 10, height: 40, zIndex: 1, marginBottom: 20, borderColor: 'black' }} onClick={bookCtx.toggleBook}> BOOK NOW! </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className='col-12 text-center' style={{ marginBottom: 150, marginTop: 100 }}>
                        <h1 style={{ marginBottom: 50 }}>GALERIE</h1>
                        <div className='thumbnail-gallery'>
                            <PhotoProvider>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_1.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_1_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_2.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_2_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_3.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_3_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_4.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_4_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_12.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_12_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_6.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_6_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_7.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_7_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_8.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_8_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_9.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_9_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_10.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_10_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_11.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_11_thumb.jpg" alt="" />
                                </PhotoView>
                                <PhotoView src="../assets/images/Event Galerie/amari-bar_event_5.jpg">
                                    <img src="../assets/images/Event Galerie/amari-bar_event_5_thumb.jpg" alt="" />
                                </PhotoView>

                            </PhotoProvider>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-12 text-center' style={{ backgroundColor: 'black', height: 350, margin: 50, padding: 100, marginBottom: 100 }}>
                            <h1 style={{ color: 'white' }}> JETZT BUCHUNG ANFRAGEN! </h1>
                            <button className='button-black' style={{ paddingLeft: 30, paddingRight: 30, marginTop: 40, height: 50, zIndex: 2, marginBottom: 50, borderColor: 'white' }} onClick={bookCtx.toggleBook}> JETZT ANFRAGEN </button>

                        </div>
                    </div>


                    <div className='col-12' style={{ paddingBottom: 100 }}>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <h1 style={{ textAlign: 'left' }}> WIR MACHEN </h1>
                                <h1 style={{ textAlign: 'left' }}> CATERING! </h1>
                                <p> Du brauchst eine mobile Bar für ein spezielles Event, oder suchst nach der passenden Kulinarik für einen besonderen Anlass? Melde dich gerne bei uns. Zusammen mit unseren Partnern können wir dir das perfekte Angebot für dein Event erstellen. Von Fingerfood, Sektempfang bis hin zur Konferenz oder Live-Cooking.</p>
                                <p>
                                    Business Catering für Konferenzen, Tagungen, Messen oder Seminare. Du bist auf der Suche nach Business Lunch, Lunchpakete oder Frühstück, melde dich jederzeit. Wir liefern auch als Kantinenersatz.
                                    Event Catering für Firmenfeiern, Weihnachtsfeiern oder Seminare. Du suchst etwas für euren Business Lunch oder Frühstück?
                                    Privates Catering für eine Hochzeit, deinen Geburtstag oder ein Grillfest. Du planst eine Gartenparty oder suchst das perfekte Essen zu einer Location?
                                </p>
                                <div>
                                    <button className='button-white-arrow' style={{ float: 'left', marginRight: 20, paddingLeft: 30, paddingRight: 30, marginTop: 5, height: 40, width: 76, zIndex: 2, backgroundSize: '25%' }} onClick={bookCtx.toggleBook}>  </button>

                                </div>
                            </div>
                            <div className='col-lg-6 col-12' style={{paddingTop: 5}}>
                                <img className='img-fluid' style={{ height: 'auto', width: 'auto' }} alt="food" src="../assets/images/Food Galerie/amari-bar_food_banner_7.jpg"></img>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

            {/* <Newsletter></Newsletter> */}
        </div>
    )

}

export default Location;
