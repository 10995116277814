import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './components/main';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from "react-helmet";
import React, { useState, useContext } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Modal, Button } from 'react-bootstrap';

// make new contexts
export const MenuContext = React.createContext();
export const ReservationContext = React.createContext();
export const BookContext = React.createContext();

// create the menu provider
const MenuProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)

  return (
    <MenuContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </MenuContext.Provider>
  )
}

// create the reservation provider
const ReservationProvider = (props) => {
  const [reservationOpenState, setReservationOpenState] = useState(false)

  return (
    <ReservationContext.Provider value={{
      isReservationOpen: reservationOpenState,
      toggleReservation: () => setReservationOpenState(!reservationOpenState),
      stateChangeHandler: (newState) => setReservationOpenState(newState.isOpen)
    }}>
      {props.children}
    </ReservationContext.Provider>
  )
}

// create the menu provider
const BookProvider = (props) => {
  const [bookOpenState, setBookOpenState] = useState(false)

  return (
    <BookContext.Provider value={{
      isBookOpen: bookOpenState,
      toggleBook: () => setBookOpenState(!bookOpenState),
      stateChangeHandler: (newState) => setBookOpenState(newState.isOpen)
    }}>
      {props.children}
    </BookContext.Provider>
  )
}


// create a navigation component that wraps the burger menu
const Navigation = () => {
  const menuCtx = useContext(MenuContext)
  const reservationCtx = useContext(ReservationContext)
  const bookCtx = useContext(BookContext)

  return (
    <>
      <Menu
        isOpen={menuCtx.isMenuOpen}
        onStateChange={(state) => menuCtx.stateChangeHandler(state)}
        customBurgerIcon={false}
        customCrossIcon={<FontAwesomeIcon icon={faCircleXmark} color="white" />}
        width={'40%'}
      >
        <a id="food" className="menu-item" href="/food">FOOD</a>
        <a id="drinks" className="menu-item" href="/drinks">DRINKS</a>
        <a id="about us" className="menu-item" href="/ueber">ABOUT US</a>
        <a id="event location" className="menu-item" href="/locations">EVENT LOCATION</a>
        <a id="jobs" className="menu-item" href="/jobs">JOBS</a>

        <h6 style={{ color: 'white', textAlign: 'center', marginTop: 80, marginBottom: -15, textDecoration: 'none' }}> - ÖFFNUNGSZEITEN -</h6>
        <h6 style={{ color: 'white', textAlign: 'center', marginBottom: -70 }}>
          Di 15:30 - 23 UHR <br></br>
          Mi 15:30 - 1 UHR <br></br>
          Do 15:30 - 1 UHR <br></br>
          Fr 15:30 - 2 UHR <br></br>
          Sa 10:00 - 2 UHR <br></br>
          So 10:00 - 23 UHR </h6>
      </Menu>

      <Menu right
        isOpen={reservationCtx.isReservationOpen}
        onStateChange={(state) => reservationCtx.stateChangeHandler(state)}
        customBurgerIcon={false}
        customCrossIcon={<FontAwesomeIcon icon={faCircleXmark} color="white" />}
        width={'40%'}
      >
        <div id="quandoo-booking-widget"></div>
        <Helmet>
          <script src="https://booking-widget.quandoo.com/index.js" data-merchant-id="93812" data-theme="dark" async></script>
        </Helmet>
      </Menu>


      <Modal show={bookCtx.isBookOpen} onHide={(state) => bookCtx.stateChangeHandler(state)} color="black" size='lg' centered >
        <Modal.Body style={{ background: 'black', overflow: 'visible' }}>
          <div>
            <div className="row justify-content-center text-center" style={{ marginTop: "30px", margin: 0 }}>
              <div style={{ padding: 0 }}>
                <Button variant='white' onClick={(state) => bookCtx.stateChangeHandler(state)} style={{ float: 'right', margin: 0, padding: 0, paddingBottom: 50 }}>
                  <FontAwesomeIcon icon={faCircleXmark} color="white" size="2x" />
                </Button>
              </div>
              <div className="col-9 " style={{ marginTop: "10px", marginBottom: 100, padding: 0 }}>
                <h1 style={{ color: "white", marginBottom: 60 }}> READY TO BOOK? </h1>
                <h5 style={{ color: "white", marginBottom: 60 }}> MELDE DICH BEI ZENO UND BESPRECHE DIE EINZELHEITEN!</h5>
                <h2 style={{ color: "white", marginBottom: 10 }}> ZENO KRATZER </h2>
                <h5 style={{ color: "white", marginBottom: 10 }}> 089 20568556</h5>
                <h5 style={{ color: "white", marginBottom: 10 }}> info@amari.bar</h5>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  )
}


function App() {
  return (
    <BrowserRouter>
      <div>
        <MenuProvider>
          <ReservationProvider>
            <BookProvider>
              <div>
                <Navigation />
                <Main />
              </div>
            </BookProvider>
          </ReservationProvider>
        </MenuProvider>

      </div>
    </BrowserRouter>
  );
}

export default App;