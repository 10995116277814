import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from './footer';
import Header from './header';
import Home from './page_components/home';
import Events from './page_components/events';
import UeberUns from './page_components/ueberUns';
// import Speisekarte from './page_components/speisekarte';
// import Getraenkekarte from './page_components/getraenkekarte';
import Location from './page_components/event_location';
import Lichthof1 from './page_components/location_lichthof1';
import Food from './page_components/food';
import Drinks from './page_components/drinks';
import Datenschutz from './rights_components/datenschutz'
import AGB from './rights_components/agb'
import Impressum from './rights_components/impressum'
import Jobs from './page_components/jobs';


class Main extends Component {

    render() {

        return (
            <div>
                <Header />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/datenschutz' element={<Datenschutz />} />
                        <Route path='/agb' element={<AGB />} />
                        <Route path='/impressum' element={<Impressum />} />
                        <Route path='/events' element={<Events />} />
                        <Route path='/ueber' element={<UeberUns />} />
                        <Route path='/foodKarte' element={<Food />} />
                        <Route path='/drinksKarte' element={<Drinks />} />
                        <Route path='/locations' element={<Location />} />
                        <Route path='/lichthof1' element={<Lichthof1 />} />
                        <Route path='/food' element={<Food />} />
                        <Route path='/drinks' element={<Drinks />} />
                        <Route path='/jobs' element={<Jobs />} />
                        <Route path='*' element={<Home />} />
                    </Routes>
                <Footer />

            </div>
        );
    }
}

export default Main; 