import React from 'react';



const Home = () => {





    return (
        <div className='home' style={{ backgroundColor: 'white' }}>

            <div className='newHomeBackground'>
                <img className='img-responsive' src='../assets/images/homebackground.jpg' alt='home' style={{ backgroundSize: 'contain' }}></img>
            </div>





            <div className='home-opening' style={{ backgroundColor: 'black', marginBottom: -50 }}>
                <div className='container'>
                    <div style={{ backgroundColor: 'black', zIndex: '1' }}>
                        <div className="row text-align-center" style={{ height: 350 }}>
                            <div className='col-lg-12 col-12'>
                                <h2 style={{ color: 'white', textAlign: 'center', marginBottom: 30, marginTop: 30 }}>- ÖFFNUNGSZEITEN -</h2>
                                <div style={{textAlign: 'center'}}>
                                    <div style={{display: 'inline-block', textAlign: 'left'}}>
                                        <h6 style={{ color: 'white',  marginBottom: 30 }}>
                                            DI 15:30 - 23 UHR <br></br>
                                            MI 15:30 - 1 UHR  <br></br>
                                            DO 15:30 - 1 UHR  <br></br>
                                            SA 10:00 - 2 UHR  <br></br>
                                            SO 10:00 - 23 UHR
                                        </h6>
                                    </div>
                                </div>

                                <h6 style={{ color: 'white', textAlign: 'center', fontSize: 12 }}> UNSERE ÖFFNUNGSZEITEN KÖNNEN ABWEICHEN </h6>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="home_partner" style={{ height: 'auto', paddingBottom: 100 }}>
                <div className='container'>
                    <div className='row align-items-center justify-content-center' style={{ height: 'auto' }}>
                        <div className='col-lg-4 col-12 text-center'>

                            <a href='https://www.salievito.de/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                <img className='img-fluid' src='../assets/images/Partner/amari-bar_partner_Salievito.jpg' alt='salievito' style={{ marginBottom: 20, marginTop: 20 }}></img>
                                <h1 style={{ textAlign: 'center' }}>Salievito</h1>
                            </a>
                            <a href='https://www.salievito.de/' target="_blank" rel="noopener noreferrer">
                                <button className='button-white-arrow' style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, height: 40, width: 40 }}> </button>
                            </a>
                            {/* <p style={{ textAlign: 'center' }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                            <div className='justify-content-center' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <button className='button-white' style={{ paddingLeft: 40, paddingRight: 40, marginTop: 10, height: 40 }}>BUTTON</button>
                            </div> */}
                        </div>
                        <div className='col-lg-4 col-12 text-center'>
                            <a href='https://www.hiaperitifs.com/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                <img className='img-fluid' src='../assets/images/Partner/amari-bar_partner_hi-aperitifs.jpg' alt='aperitifs' style={{ marginBottom: 20, marginTop: 20 }}></img>
                                <h1 style={{ textAlign: 'center' }}>hi aperitifs</h1>
                            </a>
                            <a href='https://www.hiaperitifs.com/' target="_blank" rel="noopener noreferrer">
                                <button className='button-white-arrow' style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, height: 40, width: 40 }}>  </button>
                            </a>
                            {/* <p style={{ textAlign: 'center' }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                            <button className='button-white' style={{ paddingLeft: 40, paddingRight: 40, marginTop: 10, height: 40 }}>BUTTON</button> */}
                        </div>
                        <div className='col-lg-4 col-12 text-center'>
                            <a href='https://kunstlabor.org/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                <img className='img-fluid' src='../assets/images/Partner/amari-bar_partner_Kunstlabor-2.jpg' alt='kunstlabor2' style={{ marginBottom: 20, marginTop: 20 }}></img>
                                <h1 style={{ textAlign: 'center' }}>Kunstlabor 2</h1>
                            </a>
                            <a href='https://kunstlabor.org/' target="_blank" rel="noopener noreferrer">
                                <button className='button-white-arrow' style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, height: 40, width: 40 }}>  </button>
                            </a>
                            {/* <p style={{ textAlign: 'center' }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                            <button className='button-white' style={{ paddingLeft: 40, paddingRight: 40, marginTop: 10, height: 40 }}>BUTTON</button> */}
                        </div>

                    </div>
                </div>
            </div>







        </div>
    );

}

export default Home;