import React, { Component } from 'react'
import ReactPlayer from 'react-player'
// import Newsletter from '../detail_components/newsletter'
import EventsData from '../../shared/events.json'
import Event from '../detail_components/event';

export default class events extends Component {
    render() {
        return (
            <div className='events' style={{ backgroundColor: 'white' }}>

                <div className='container' style={{ paddingBottom: 50 }}>
                    <div className='row justify-content-center'>
                        <div className='col-12' style={{ backgroundColor: 'black', marginTop: 50 }}>
                            <div className='player-wrapper' style={{ width: 'auto', height: 'auto' }}>
                                <ReactPlayer
                                    className='react-player'
                                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                                    playing
                                    muted
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center' style={{ height: 550, backgroundColor: 'black', marginTop: 100, marginBottom: 150 }}>
                            <row className='row justify-content-center'>
                                <div className='col-lg-5 col-md-12 col-12 text-center'>
                                    <h1 style={{ color: 'white', marginTop: 100, marginBottom: 50 }}>UPCOMING</h1>
                                    <h3 style={{ color: 'white', marginBottom: 20 }}>{EventsData.Upcoming.name}</h3>
                                    <p style={{ color: 'white', marginBottom: 20 }}> {EventsData.Upcoming.dateAndTime} </p>
                                    <p style={{ color: 'white', marginBottom: 30 }}> {EventsData.Upcoming.text} </p>
                                    <a href={EventsData.Upcoming.link}><button type="submit" className='button-black' style={{ borderColor: 'white', borderRadius: 5, paddingTop: 10, paddingBottom: 5, paddingLeft: 30, paddingRight: 30 }} onClick={() => this.onSubmit()}>TICKETS</button></a>
                                </div>
                            </row>
                        </div>
                        <div className='col-12 text-center'>
                            <h1 style={{ marginBottom: 50 }}>EVENT KALENDER</h1>
                        </div>
                        <div className='col-10' style={{ marginBottom: 200 }}>
                            <div className='row justify-content-center'>
                                {EventsData.Events.map(item => {
                                    return (
                                        <Event key={item.id} dateAndTime={item.dateAndTime} name={item.name} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div >

                {/* <Newsletter></Newsletter> */}
            </div >
        )
    }
}
