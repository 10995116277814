import React, { Component } from 'react'
import JobsData from '../../shared/jobs.json'
import Event from '../detail_components/event';

export default class jobs extends Component {
    render() {
        return (
            <div className='jobs' style={{ backgroundColor: 'white' }}>




                <div className='container' style={{ paddingBottom: 50 }}>
                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <img className='img-fluid' style={{ height: 'auto', width: 'auto', paddingTop: 50 }} alt="food" src="../assets/images/amari-bar_food_banner.jpg"></img>
                        </div>
                        <div className='col-12 text-center'>
                            <h1 style={{ marginTop: 100 }}>JOIN THE TEAM @</h1>
                            <h4 style={{ marginBottom: 100}}> INFO@AMARI.BAR</h4>
                        </div>
                        <div className='col-10' style={{ marginBottom: 200 }}>
                            <div className='row justify-content-center'>
                                {JobsData.JobsList.map(item => {
                                    return (
                                        <Event key={item.id} dateAndTime={item.hours} name={item.name} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
